import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import emailIcon from '../../../../../Assets/wes-login/emailIcon.svg';
import mesh3d from '../../../../../Assets/wes-login/mesh-3d.svg';
import nsdcTrust from '../../../../../Assets/wes-login/nsdc-trust.svg';
import MobileLogin from '../../NSDC/login/mobile-ui';
import { BRANDING, TOP_BAR_LINKS, TRENTIAL_PERKS } from './constants';
import LoginForm from './form';
import styles from './styles';

function Login() {
  const veryBigScreen = useMediaQuery('(min-width:2050px)');
  const verySmallScreen = useMediaQuery('(max-width:500px)');
  return (
    <Box sx={{ backgroundColor: '#FAFAFA', height: { xs: 'auto', sm: 'auto' }, display: 'flex', flexFlow: 'column' }}>
      <Box sx={styles.topbar}>
        {!verySmallScreen ? <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item>
            <Box component={'img'} src={nsdcTrust} sx={{ height: '5rem' }} />
          </Grid>
          <Grid item sx={{ display: 'flex', columnGap: { xs: '1rem', sm: '4rem' }, alignItems: 'center' }}>
            {
              TOP_BAR_LINKS.map((row) =>
                <Box
                  component={'button'}
                  className='wes-auth-caption'
                  sx={{ all: 'unset', height: 'fit-content', cursor: 'pointer', color: '#131313 !important', fontWeight: '400 !important', whiteSpace: 'nowrap' }}
                  onClick={() => document.getElementById(row.elementId)?.scrollIntoView({ behavior: 'smooth' })}
                >
                  {row.label}
                </Box>)
            }
            <Button href={`mailto:${process.env.REACT_APP_ORG_MAIL}`} disableRipple disableFocusRipple sx={{ p: '.9rem 1.6rem', border: '1px solid' }} startIcon={<img src={emailIcon} />}>
              {'Contact For Support'}
            </Button>
          </Grid>
        </Grid> : <MobileLogin />}
      </Box>
      <Grid
        container sx={{ pb: { xs: '3.5rem', sm: '2.2rem' },
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          // flexDirection: {sm: 'column', md: 'row'},
          alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={12} lg={veryBigScreen ? 5 : 5} sx={{ padding: '1em'}}>
          <Grid container>
            <Grid item xs={0} lg={2}></Grid>
            <Grid item xs={12} lg={10} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Box sx={{width: 'fit-content', margin: 'auto'}}>
                <Box sx={{ display: 'flex', width: '100%', columnGap: '3rem', alignItems: 'center' }}>
                  {
                    BRANDING.map((row) =>
                      <Box component={'img'} src={row.imgSrc} sx={row.imgStyle} />)
                  }
                </Box>
                <Box sx={{ width: { xs: '100%' }, zIndex: '5', padding: '1em' }}>
                  <Typography className='wes-auth-title' sx={{ color: '#000000 !important' }}>
              All-in-one
                    {' '}
                    <Box component='span' sx={{ color: '#1C43C3' }}>
                credential
                      <br />
                      {' '}
                verification platform
                    </Box>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                  <Box sx={{ display: 'flex', columnGap: '1rem', backgroundColor: '#fff', border: '1px solid rgba(238, 238, 238, 1)', mb: '1rem', width: 'fit-content', borderRadius: '16px', padding: { xs: '1.7rem', xl: '2.3rem' } }}>
                    {
                      TRENTIAL_PERKS.map((row, index) =>
                        <Box sx={{ display: 'flex', columnGap: '3rem', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', rowGap: '1.5rem', flexFlow: 'column' }}>
                            <Box component='img' src={row.imgSrc} sx={row.imgStyle} />
                            <Typography className='wes-auth-perks-text' sx={{ color: '#212121 !important', textAlign: 'center' }}>
                              {row.label}
                            </Typography>
                          </Box>
                          {
                            index !== TRENTIAL_PERKS?.length - 1 &&
                      <Box sx={{ backgroundColor: '#00000033', width: '1px', height: '50%' }} />
                          }
                        </Box>)
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item xs={12} lg={veryBigScreen ? 7 : 7}
          sx={{ display: {xs: 'none', sm: 'flex'},
            alignItems: 'center',
            justifyContent: 'flex-end' }}>
          <Box sx={{
            backgroundImage: `url(${mesh3d})`,
            backgroundPosition: 'center',
            backgroundOrigin: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            // maxWidth: '50vw',
            height: '70vh',
            margin: 'auto',
            boxShadow: {xs: 'inset 0px 0px 40px 40px #FAFAFA', md: 'inset 20px 20px 40px 40px #FAFAFA'},
            // padding: '1em'

          }}>
            <Box sx={styles.loginWrapper}>
              <LoginForm />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
