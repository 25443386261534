/* eslint-disable */

import ExpandIcon from '@mui/icons-material/ExpandCircleDownRounded';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import emailIcon from '../../../../../../Assets/wes-login/emailIcon.svg';
import nsdcTrust from '../../../../../../Assets/wes-login/nsdc-trust.svg';
import LoginForm from '../form';
import SideBar from '../sidebar';
import styles from '../styles';
import { BRANDING, TRENTIAL_PERKS } from './../constants';

function MobileLogin() {
    const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <Box>
       <Drawer anchor='right' open={openSidebar} onClose={() => setOpenSidebar(false)} >
        <SideBar setOpen={setOpenSidebar} />
      </Drawer>
      <Box sx={{ display: 'flex', flexFlow: 'column'}}>
        <Box sx={styles.topbar}>
            <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Grid item>
                <Box component={'img'} src={nsdcTrust} sx={{height: '5rem' }} />
            </Grid>
            <Grid item sx={{display: 'flex', columnGap: {xs: '1rem', sm: '4rem'}, alignItems: 'center'}}>
                <IconButton href={`mailto:${process.env.REACT_APP_ORG_MAIL}`} disableRipple disableFocusRipple sx={{borderRadius: '6px', p: '1rem', border: '1px solid #1C43C3'}}>
                    <img src={emailIcon} />
                </IconButton> 
                <IconButton sx={{p: '1rem'}} onClick={() => setOpenSidebar((prev) => !prev)}>
                    <MenuIcon sx={{color: '#1C1B1F'}} />
                </IconButton>
            </Grid>
            </Grid>
        </Box>
        <Box sx={{display: 'flex', flexFlow: 'column', flex: 1, rowGap: '4.5vh', padding: '1.5rem', pt: '4.5vh', pb: '6.4vh'}}>
            <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', columnGap: '3rem', alignItems: 'center'}}>
                {
                BRANDING.map((row) =>
                    <Box component={'img'} src={row.imgSrc} sx={row.imgStyle} />)
                }
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between', flex: 1, rowGap: '6vh'}}>
                <Box sx={styles.loginWrapperMobile}>
                <LoginForm />
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton sx={{width: 'fit-content'}}
                onClick={() => document.getElementById('wes-auth-all-in-one').scrollIntoView({behavior: 'smooth'})}
                >
                    <ExpandIcon sx={{ color: '#1C43C3', height: '3.7rem', width: '3.7rem'}}/>
                </IconButton>
                </Box>
            </Box>    
        </Box>
      </Box>

        <Box sx={{p: '3.7rem 2.9vw', pb: '4rem', display: 'flex', flexFlow: 'column', rowGap: '3.5rem'}} id="wes-auth-all-in-one">
         <Box sx={{width: {xs: '100%', blg: '150% !important'}, zIndex: '5'}}>
            <Typography className='wes-auth-title' sx={{color: '#000000 !important'}}>
                All-in-one
              {' '}
              <Box component='span' sx={{color: '#1C43C3'}}>
              credential verification platform
              </Box>
            </Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
            <Box sx={{display: 'flex', columnGap: '1rem', backgroundColor: '#fff', border: '1px solid rgba(238, 238, 238, 1)', mb: '1rem', width: 'fit-content', borderRadius: '16px', padding: {xs: '1.7rem', xl: '2.3rem'}}}>
              {
                TRENTIAL_PERKS.map((row, index) =>
                  <Box sx={{display: 'flex', columnGap: '3rem', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', rowGap: '1.5rem', flexFlow: 'column'}}>
                      <Box component='img' src={row.imgSrc} sx={row.imgStyle} />
                      <Typography className='wes-auth-perks-text' sx={{color: '#212121 !important', textAlign: 'center'}}>
                        {row.label}
                      </Typography>
                    </Box>
                    {
                      index !== TRENTIAL_PERKS?.length - 1 &&
                      <Box sx={{backgroundColor: '#00000033', width: '1px', height: '50%'}} />
                    }
                  </Box>)
              }
            </Box>
          </Box>

        </Box>
    </Box>
  )
}

export default MobileLogin